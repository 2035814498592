export default {
  appName: '悟空图像',
  login: {
    welcomeToLogin: '欢迎登录',
    firstLogin: '未注册用户使用手机号快捷登录即视为注册',
    codeLogin: '手机登录',
    accountLogin: '密码登录',
    apply: '注册',
    inputPhone: '请输入手机号',
    inputCode: '请输入验证码',
    getCode: '获取验证码',
    login: '登录',
    inputAccount: '请输入账号',
    inputPassword: '请输入密码',
    thirdPartyLogin: '第三方登录方式',
    loginAgreement: '登录即表明你同意',
    agreement: '《用户协议》',
    and: '和',
    policy: '《隐私政策》',
    privacyPolicyUrl: '/static/privacy_policy_zh-cn.html',
    userAgreementUrl: '/static/user_agreement_zh-cn.html',
    materialAgreementUrl: '/static/materialAgreement_zh.html',
    close: '关 闭',
    charactersLong: '长度在11个字符',
    accountCannotBeEmpty: '账号不能为空',
    loginSucceeded: '登录成功',
    codeError: '错了哦，验证码输入错误！',
    tip: '提示',
    logoutTip: '此操作将退出登录，是否继续？',
    confirm: '确定',
    cancel: '取消',
    autoLogin: '30天内自动登录',
    needNickName: '必须输入昵称',
    nickNameRules: '昵称2~12个字符之间，可以汉字，字母和数字',
    nickNameNoSpace: '昵称不能输入纯空格',
    realNameRules: '长度不超过12个字符',
    qqRules: 'qq号码格式不对',
    textRules: '简介字数不能大于200',
    emailRules: 'email格式输入错误',
    needAccount: '账号不能为空',
    needPass: '请输入密码',
    needPhone: '请输入手机号',
    needCode: '请输入验证码',
    wrongFormat: '格式错误'
  },
  editUser: {
    edit: '编辑资料',
    account: '账号',
    nickName: '昵称',
    realName: '真实姓名',
    enterName: '请输入真实姓名',
    sex: '性别',
    nan: '男',
    nv: '女',
    birthday: '生日',
    text: '简介',
    clickToEdit: '点击编辑填写',
    clickToDate: '请输入日期',
    time: '注册时间',
    email: '邮箱',
    notBind: '未绑定',
    close: '关闭',
    save: '保存',
    enterNickName: '请输入昵称',
    success: '修改成功！'
  },
  editPassword: {
    title: '修改密码',
    old: '原密码',
    new: '新密码',
    check: '确认密码',
    enterNew: '请输入新密码',
    enter: '请再次输入密码',
    text: '密码长度8-20位，需包含数字、字母或符号，至少包含2种以上元素',
    send: '提交',
    reset: '重置',
    notSame: '两次输入密码不一致!',
    changeSuccess: '修改成功！',
    sending: '已发送，注意查看！'
  },
  setPassword: {
    title: '设置密码',
    phone: '手机号',
    ma: '验证码',
    enterPassword: '请输入密码',
    check: '确认密码',
    enter: '请再次输入密码',
    text: '密码长度8-20位，需包含数字、字母或符号，至少包含2种以上元素',
    send: '提交',
    reset: '重置',
    notSame: '两次输入密码不一致!',
    changeSuccess: '修改成功！',
    bind: '请先绑定手机号！',
    success: '手机号绑定成功！'
  },
  changePhone: {
    title: '更换手机号',
    phone: '旧手机号',
    ma: '验证码',
    newPhone: '新手机号',
    enterPhone: '请输入需要更换的手机号',
    enterOldPhone: '输入旧的手机号',
    enterMa: '请输入验证码',
    enterNowPhone: '请输入当前绑定的手机号',
    send: '提交',
    reset: '重置',
    getMa: '获取验证码'
  },
  bindWX: {
    title: '绑定微信',
    phone: '手机号',
    ma: '验证码',
    getMa: '获取验证码',
    enterMa: '请输入验证码',
    send: '确定',
    bindFirst: '请先绑定手机号！',
    enterPhone: '请输入手机号'
  },
  wxLogin: {
    title: '微信登录',
    minTitle: '通过微信登录需要绑定手机号',
    enterPhone: '请输入手机号',
    enterMa: '输入验证码',
    getMa: '秒后重新获取',
    send: '完成绑定'
  },
  feedback: {
    title: '意见反馈',
    ways: '您的联系方式：',
    enterWays: '请输入您的联系方式',
    problems: '请选择您在使用中遇到了什么问题：',
    disProblems: '请详细描述在使用悟空图像中出现的问题',
    text: '请详细描述您在使用中出现的问题，有助于我们快速定位问题的原因，为您提供更优质的服务',
    score: '软件评分：',
    suggestion: '改进建议',
    send: '提交',
    enterPhone: '请输入手机号',
    cannotUse: '功能异常',
    wifi: '网络异常',
    bug: '闪退/崩溃',
    slow: '慢/效率低',
    tooDifficult: '太复杂',
    other: '其他',
    no: '没问题',
    success: '感谢您的建议,我们会尽快处理！',
    select: '请选择您所遇到的问题！'
  },
  logout: {
    title: '提示',
    text: '此操作将退出登录, 是否继续?',
    cancel: '取消',
    send: '确定',
    success: '已退出登录！'
  },
  layoutMain: {
    edit: '编辑资料',
    mainPage: '首页',
    party: '团队管理',
    message: '消息中心',
    notice: '消息通知',
    vip: '会员卡激活',
    download: '我的下载',
    buy: '订单信息',
    discount: '优惠卡券',
    cloudSpace: '云空间',
    creater: '创作者中心',
    partner: '合伙人中心',
    partnerOrder: '合伙人订单',
    setPic: '头像设置',
    seePic: '图片预览',
    changePic: '更改头像',
    cancel: '取消',
    confirm: '确定',
    superVip: '超级会员',
    active: '立即开通',
    expire: '到期',
    renewal: '续费',
    managePackages: '安装包管理',
    manageMaterials: '首页素材管理',
    manageWealth: '财富管理',
    worksReview: '作品审核',
    promotionalActivities: '活动宣传',
    promptToUpload: '请先上传预览图片！',
    prompt1: '修改成功！',
    prompt2: '修改失败，稍后再试！'
  },
  editUserIndex: {
    changePass: '修改密码',
    setPass: '设置密码',
    bindPhone: '绑定手机号',
    changeBind: '更换手机号',
    feedBack: '意见反馈',
    logOut: '退出登录'
  },
  mainPage: {
    title: '云空间',
    option: '扩容',
    enter: '进入',
    money: '续费',
    pspace: '个人空间',
    cspace: '企业空间'
  },
  cloudSpace: {
    title: '全部文件',
    search: '搜索',
    document: '文件',
    upload: '上传',
    nodata: '暂无数据',
    uptime: '上传时间',
    size: '大小',
    send: '发布',
    chooseAll: '全选',
    searchRes: '搜索结果',
    uploadFile: '上传文件',
    chooseFile: '选择文件',
    max: '最多可上传4个文件',
    newFile: '新建',
    rename: '重命名',
    delete: '删除',
    chooseDelete: '请选择想要删除的文件夹',
    warning1: '删除后，如果文件已分享给其他人，其他人也将无法访问！',
    warning2: '删除文件',
    confirm: '确认',
    cancel: '取消',
    garbage: '回收站',
    clean: '一键清理',
    cleanTip: '操作成功！',
    cleanTip2: '暂无数据清理！',
    cleanTip3: '请先选择要操作的数据！',
    cleanTip4: '是否删除全部文件，文件删除后不可恢复！',
    name: '名称',
    deltime: '删除时间',
    delperson: '删除者',
    see: '查看',
    open: '打开',
    download: '下载',
    move: '移动到...',
    copy: '复制到...',
    share: '分享',
    paste: '粘贴',
    Management: '权限管理',
    moreDel: '批量删除',
    moreMove: '批量移动',
    moreCopy: '批量复制',
    toBefore: '恢复',
    deleteOnce: '彻底删除',
    chooseMoveFolder: '请选择想要移动的文件或文件夹!',
    chooseMoveTarget: '请选择想要移动到的位置!',
    chooseCopyTarget: '请选择想要复制到的位置!',
    chooseWrong: '您选择的是文件夹，请选择文件!',
    newFolder: '新建文件夹',
    newFolderTip: '文件名字只能输入中英文、中英文符号、数字!',
    uploadTohere: '存储至此',
    enterName: '请输入文件名',
    uploading: '上传中'
  },
  upLoadWorks: {
    title: '发布作品',
    type: '作品类型',
    style: '作品风格',
    publish: '作品提交',
    text1: '请输入作品名称，最多10个字符',
    addWorks: '添加作品',
    text2: '输入后回车，每个标签限制10个字符',
    text3: '添加标签可以把作品自动推荐给可能感兴趣的人',
    text4: '输入作品说明',
    back: '返回',
    waiting: '提交审核',
    needName: '请输入作品名称',
    needStyle: '请选择作品风格',
    needDocument: '请选择作品文件',
    needLabel: '请输入作品标签',
    needPrePic: '请选择作品预览图',
    needType: '请选择作品类型',
    nameLong: '作品名称最多10个字符',
    sizeMax: '上传预览图不能大于3MB！',
    sizeRules: '上传预览图尺寸：宽度400~2000像素，高度400~2000像素!',
    sizePicMax: '上传文件不能大于64MB!',
    onlyOne: '最多上传一个！'
  },
  payForCloud: {
    title: '云空间扩容',
    pay: '云空间续费',
    payoff85: '85折',
    payoff66: '66折',
    yearpayoff: '一年以上享折扣',
    year: '年',
    time: '有效时间至',
    infact: '实际支付金额',
    yuan: '元',
    buy: '购买'
  },
  moveDiv: {
    title: '移动弹窗',
    copy: '复制弹窗',
    movetoHere: '移动至此',
    copytoHere: '复制至此',
    cancel: '取消',
    files: '文件',
    time: '上传时间',
    size: '大小',
    allFiles: '全部文件',
    enterName: '请输入文件名'
  },
  share: {
    // title: '分享文件(夹):',
    // shareLink: '分享链接',
    // time: '有效期：',
    // forever: '永久有效',
    // year: '一年',
    // sevendays: '七天',
    // mins: '30分钟',
    // createLink: '创建分享链接',
    // done: '分享链接及提取码已生成',
    // text1: '通过微信、QQ、微博、QQ空间等分享给您的好友吧'
    text1: '引领 ⌜AI+设计⌟',
    text2: '给您分享了一张图片',
    text3: '长按或截图保存分享',
    btnText1: '分享给好友',
    btnText2: '复制图片链接',
    btnText3: '保存图片到本地',
    btnText4: '请输入提取码',
    user: '用户',
    confirm: '确认'
  },
  memberActivation: {
    title: '悟空图像PC端激活',
    hello: '你好！',
    ma: '激活码',
    enter: '* 请输入16位激活码，字母区分大小写',
    imm: '立即激活',
    check: '查询时长',
    info: '激活码使用说明：',
    text1: '开通的时长是',
    month: '个月',
    confirm: '确 定',
    success: '恭喜，激活成功！',
    tip: '提示',
    text2: '1、请在有效期内激活，逾期激活码失效。',
    text3: '2、会员有效期从激活日算起，同一账号使用多个激活码，会员期自动延长。',
    text4: '3、如当前是普通会员，激活超级会员后，优先消耗超级会员，超级会员过期后，恢复为普通会员。',
    text5: '4、激活成功后，即可享有悟空会员服务。',
    text6: '5、在法律允许的范围内，悟空享有解释权，如有疑问，请联系客服。',
    text7: '开通会员，开通后自动激活',
    text8: '如果您没有激活码，可以',
    text9: '开通的时长是',
    text10: '个月',
    text11: '点击这里'
  },
  cloudNone: {
    none: '这里什么也没有...快去上传文件吧'
  },
  main: {
    all: '共',
    page: '页',
    message: '条',
    change: '条/页',
    jump: '跳至'
  },
  loginTitle: {
    title: '悟空图像'
  },
  routers: {
    login: '登录',
    mainPage: '首页',
    user: '用户中心',
    cloudSpace: '云空间'
  },
  logoff: {
    logoffTitle: '注销账号',
    text1: '为保证你的账号安全，在你提交的注销申请生效前，需同时满足以下条件：',
    text2: '1、账号处于安全状态',
    text3: '账号处于正常使用状态，无被盗风险或处于封禁状态等异常状态',
    text4: '2、账号财产已结清',
    text5: '没有资产、欠款、未结清的资金和虚拟权益，本帐号及通过本帐号接入的第三方中没有未完成或存在争议的服务',
    text6: '3、账号权限解除',
    text7: '帐号已解除与其他产品的授权登录或绑定关系',
    text8: '4、账号无任何纠纷，包括投诉举报、诉讼等',
    text9: '您正在取消您的帐户，我们会核实您的身份',
    text10: '您的帐户已成功取消。感谢您使用我们的服务，我们期待再次见到您！',
    text11: '亦心科技',
    read: '我已阅读并同意',
    agree: '《账号注销协议》',
    logoffBtn: '注销账号',
    logoffedBtn: '账号已注销',
    dialogTitle: '账号注销协议',
    logoffSuccess: '注销成功',
    logoffUrl: '/static/logoff_agreement_pad_zh-cn.html',
    verify: '验证',
    finish: '完成',
    cancel: '取消',
    confirm: '确定',
    logoffTip: '确认要注销此账号吗?',
    logoffMsg: '请您先登录才能注销哦!'
  },
  giftGet: {
    title: '用户礼包',
    Collection: '领取中',
    CollectionFailed: '领取失败',
    CollectionSuccess: '领取成功',
    OnlineStrategy: '检测不到网络',
    networkAnomaly: '网络异常',
    prompt: '参数错误',
    rule: '超级会员权益一览',
    timeSlot: '会员有效期至'
  }
}
